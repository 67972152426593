import Div from "components/Div";
import Footer from "components/common/Footer";
import Logo from "components/common/Logo";
import {
  AppContainer,
  AppLayout,
  paddingLeftCss,
} from "components/common/layout";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "theme";
import ContactInformation from "./ContactInformation";
import PaymentHeader from "./PaymentHeader";
import { Form as FForm, Formik } from "formik";
import * as yup from "yup";
import PaymentElements from "./PaymentElements";
import SummaryValues from "./SummaryValues";
import SummaryDetail from "./SummaryDetail";
import { loadStripe } from "@stripe/stripe-js";
import { shoutoutI } from "firebase-tools";
import { generatePath, useHistory } from "react-router";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useMediaQuery } from "hooks";
import { MobileDivider, SectionHeader } from "components/common";
import { usePaymentContext } from "components/PaymentContextProvider";
import { ROUTES, useShoutoutOrderRedirect } from "..";
import { useAppContext } from "AppContextProvider";
import { ROUTES as APP_ROUTES } from "App";
import { ErrorMessageWarning } from "components/common/formikInputs";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);

const Header = styled(Div)`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media all and ${(props) => props.theme.media.mobile} {
    justify-content: left;
    height: 60px;
    ${paddingLeftCss}
  }
`;

export const Section = styled(Div)`
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08);
  margin-top: 25px;
  @media all and ${(props) => props.theme.media.mobile} {
    box-shadow: none;
    margin-top: 0px;
  }
`;

const Columns = styled(Div)`
  display: grid;
  grid-template-columns: 1.7fr 1fr;
  grid-gap: 65px;
  margin-bottom: 80px;
  @media all and ${(props) => props.theme.media.mobile} {
    display: flex;
    grid-gap: 0;
    flex-direction: column-reverse;
  }
`;

const ErrorMessageWrapper = styled(Div)`
  margin-top: 25px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
  }
`;

const yupRequired = yup
  .string()
  .nullable()
  .required("This is a required field and cannot be blank.");
const validationSchema = yup.object().shape({
  firstName: yupRequired,
  lastName: yupRequired,
  email: yupRequired.email("This does not appear to be a valid email address."),
});

const PaymentForm = () => {
  useShoutoutOrderRedirect();
  const { shoutout } = usePaymentContext();
  return (
    <Elements stripe={stripePromise}>
      {shoutout && <PaymentFormWithData />}
    </Elements>
  );
};

const headerUI = (
  <Header>
    <Div mcontainer>
      <Div forDesktop alignCenter>
        <Logo />
      </Div>
      <Div forMobile alignCenter>
        <Logo height="18" width="85.5" />
      </Div>
    </Div>
  </Header>
);

const summaryUI = (
  <Div>
    <Div mcontainer_left>
      <SummaryDetail />
    </Div>
    <Div forDesktop>
      <SummaryValues />
    </Div>
  </Div>
);

const formInitialValues = {
  firstName: null,
  lastName: null,
  email: null,
};

const PaymentFormWithData = () => {
  const {
    shoutout,
    requestId,
    setCustomerInformation,
    shoutoutId,
  } = usePaymentContext();
  const { appIsLoading, setAppIsLoading } = useAppContext();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const [cardError, setCardError] = useState(null);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [isPaymentItemSet, setIsPaymentItemSet] = useState(false);
  const api = useRef<shoutoutI.payments.Payments>(null);
  const isMobile = useMediaQuery(theme.media.mobile);

  useEffect(() => {
    if (isMobile) document.body.style.backgroundColor = theme.colors.white;
    else document.body.style.backgroundColor = theme.colors.background;
  }, [isMobile]);

  useEffect(() => {
    if (stripe) {
      api.current = shoutoutI.payments(stripe);
      api.current.init();

      api.current.on("onPaymentRequestInit", (result) => {
        if (result) setPaymentRequest(api.current.paymentRequest);
        // triggers onPaymentRequestUpdate
        api.current.setItem({
          amount: shoutout.unitPrice + shoutout.processingFeeAmount,
          label: shoutout.name,
          id: requestId,
          type: "shoutout",
          quantity: 1,
        });
      });

      api.current.on("onPaymentRequestUpdate", (result) => {
        if (result) {
          setPaymentRequest(api.current.paymentRequest);
          setIsPaymentItemSet(true);
        } else setPaymentRequest(null);
      });

      api.current.on("onPaymentComplete", (response) => {
        setAppIsLoading(false);
        setCustomerInformation((prev) => ({
          ...prev,
          paymentData: response,
        }));
        if (response.success)
          history.push(
            generatePath(
              `${APP_ROUTES.SHOUTOUT_ORDER}${ROUTES.ORDER_CONFIRMATION}`,
              { shoutoutId }
            )
          );
        if (response.error) setCardError(response.error);
      });
    }
  }, [stripe]);

  const setPaymentData = (values) => {
    setCustomerInformation(values);
    api.current.setCustomer(values);
  };

  const handleSubmit = (values) => {
    setPaymentData(values);
    setAppIsLoading(true);
    const card = elements.getElement(CardElement);
    api.current.submitPayment({
      paymentMethod: { card },
    });
  };

  const formUI = (
    <Div>
      <Formik
        initialValues={formInitialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount={true}
        enableReinitialize
      >
        {({ values, isValid: formIsValid, touched, errors, submitForm }) => (
          <FForm>
            <Div forDesktop>
              <PaymentHeader />
            </Div>
            <Div forMobile>
              <SummaryValues />
            </Div>
            <MobileDivider />
            <ErrorMessageWrapper>
              <ErrorMessageWarning />
            </ErrorMessageWrapper>
            <SectionHeader>Payment</SectionHeader>
            <ContactInformation />
            <MobileDivider />
            <PaymentElements
              onSubmit={submitForm}
              cardError={cardError}
              paymentRequest={paymentRequest}
              isProcessingPayment={appIsLoading}
              displayPaymentRequestButton={
                paymentRequest && isPaymentItemSet && formIsValid
              }
              onPaymentRequestButtonClick={() => setPaymentData(values)}
            />
          </FForm>
        )}
      </Formik>
    </Div>
  );

  const isLoading = !stripe || !elements;
  return (
    <AppLayout>
      {headerUI}
      <AppContainer>
        {!isLoading && (
          <Columns>
            {formUI}
            <Div>
              {summaryUI}
              <MobileDivider />
            </Div>
          </Columns>
        )}
      </AppContainer>
      <Footer
        withLogo={false}
        background={isMobile ? theme.colors.white : theme.colors.background}
      />
    </AppLayout>
  );
};

export default PaymentForm;
