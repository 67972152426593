import styled, { css } from "styled-components";
import Div from "components/Div";
import { generatePath, useHistory } from "react-router";
import { WhiteButton } from "components/common";
import { Icon } from "notes";
import { VerticalEllipsis } from "components/common/icons";
import { useState } from "react";
import Dropdown from "components/common/Dropdown";
import { getDisplayDate } from "components/utils";
import { ROUTES } from "App";
import { RequestStatus } from "@musicaudienceexchange/shoutout-interface/lib/firestore/shoutouts";

const Wrapper = styled(Div)`
  border: solid 1px ${(props) => props.theme.colors.gray20};
  border-radius: 8px;
  @media all and ${(props) => props.theme.media.mobile} {
    border: none;
  }
`;

const Header = styled(Div)`
  background: ${(props) => props.theme.colors.gray05};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 24px 52px;
  @media all and ${(props) => props.theme.media.mobile} {
    padding: 10px 0;
  }
`;

const Content = styled(Div)`
  padding: 32px 52px;
  @media all and ${(props) => props.theme.media.mobile} {
    padding-right: 0;
    padding-left: 0;
  }
`;

const Image = styled.img`
  height: 180px;
  width: 180px;
  @media all and ${(props) => props.theme.media.mobile} {
    height: 156px;
    width: 156px;
  }
`;

const OrderDetail = styled(Div)`
  font-style: italic;
  color: ${(props) => props.theme.colors.inputPlaceholderText};
  font-size: 17px;
`;

const ViewOrderButton = styled(Div)`
  color: ${(props) => props.theme.colors.linkDefault};
  font-weight: bold;
  display: flex;
  align-items: center;
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const Avatar = styled.img`
  height: 24px;
  width: 24px;
  border-radius: 100%;
  user-select: none;
`;

const OrderSummaryWrapper = styled(Div)`
  display: flex;
  @media all and ${(props) => props.theme.media.mobile} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

const OrderSummaryData = styled(Div)`
  margin-left: 50px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-left: 0px;
  }
`;

const ItemTitle = styled(Div)`
  font-size: 20px;
  @media all and ${(props) => props.theme.media.mobile} {
    font-size: 17px;
    margin-right: 15px;
  }
`;

const OrderDetailsLink = styled(Div)`
  margin-top: 5px;
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  margin-left: 10px;
  color: ${(props) => props.theme.colors.linkDefault};
`;

const IconWrapper = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  height: 30px;
  width: 30px;
  margin-right: -10px;
`;

const dropdownCss = css`
  border-radius: 0;
  width: 200px;
  margin-top: -30px;
  margin-left: -205px;
  padding: 8px 15px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
`;

const OrderDetailsMenuItem = styled(Div)`
  color: ${(props) => props.theme.colors.text};
  font-size: 17px;
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  display: flex;
  align-items: center;
  svg {
    path {
      fill: #9ca4a8;
    }
  }
`;

const ORDER_RECEIVED_LABEL = "Order Received";
const requestStatusLabels = {
  [RequestStatus.received]: ORDER_RECEIVED_LABEL,
  [RequestStatus.processing]: ORDER_RECEIVED_LABEL,
  [RequestStatus.pending]: "Order Pending",
  [RequestStatus.completed]: "Order Delivered",
  [RequestStatus.expired]: "Order Expired",
  [RequestStatus.rejected]: "Order Rejected",
};

const OrderItem = ({ order }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const history = useHistory();
  const shoutoutName = order?.shoutout?.name;
  const shoutoutIconPath = order?.shoutout?.assets?.icon?.path;

  const openOrderDetail = () => {
    history.push(
      generatePath(ROUTES.SHOUTOUT_REQUEST, {
        shoutoutId: order.shoutoutId,
        requestId: order.id,
      })
    );
  };

  const onCloseDropdown = () => setIsDropdownOpen(false);

  const mobileHeaderOptionsUI = (
    <Div forMobile>
      <IconWrapper
        positionRelative
        onClick={() => {
          setIsDropdownOpen(true);
        }}
      >
        <VerticalEllipsis />
      </IconWrapper>
      <Dropdown
        isOpen={isDropdownOpen}
        onClose={onCloseDropdown}
        dropdownCss={dropdownCss}
      >
        <OrderDetailsMenuItem dflex onClick={openOrderDetail}>
          <Icon tertiary name="USD" />
          <Div ml_7>Order Details</Div>
        </OrderDetailsMenuItem>
      </Dropdown>
    </Div>
  );

  const headerUI = (
    <Header>
      <Div mcontainer spaceBetween alignCenter>
        <Div>
          <Div fs_14 lite forDesktop>
            ORDER PLACED
          </Div>
          <Div bold dflex alignCenter>
            <Div fs_17 mt_5>
              {getDisplayDate(order.createdAt, "MMMM DD, YYYY")}
            </Div>
            <OrderDetailsLink forDesktop onClick={openOrderDetail}>
              (Order Details)
            </OrderDetailsLink>
          </Div>
        </Div>
        {mobileHeaderOptionsUI}
      </Div>
    </Header>
  );

  const orderSummaryUI = (
    <Div>
      <OrderSummaryWrapper>
        <Image src={shoutoutIconPath} />
        <OrderSummaryData>
          <Div dflex>
            <Avatar src={shoutoutIconPath} />
            <Div ml_10 c_border mr_15>
              {shoutoutName}
            </Div>
          </Div>
          <ItemTitle bold mt_10 mr_15>
            Personalized Video Message From {shoutoutName}
          </ItemTitle>
          <ViewOrderButton dflex mt_10 onClick={openOrderDetail}>
            {requestStatusLabels[order.status] || ORDER_RECEIVED_LABEL}
            <Icon tertiary name="Next" />
          </ViewOrderButton>
        </OrderSummaryData>
      </OrderSummaryWrapper>
    </Div>
  );

  return (
    <Wrapper>
      {headerUI}
      <Div mcontainer>
        <Content spaceBetween>
          <Div w100>{orderSummaryUI}</Div>
          <Div forDesktop>
            <WhiteButton forDesktop onClick={openOrderDetail}>
              View Item
            </WhiteButton>
          </Div>
        </Content>
      </Div>
    </Wrapper>
  );
};

export default OrderItem;
