import { ThemeProvider } from "styled-components";
import { theme } from "./theme";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";
import VideoMessage from "components/VideoMessage";
import "./App.css";
import Login from "components/Login";
import { LoginDev } from "components/LoginDev";
import { AppContextProvider, useAppContext } from "AppContextProvider";
import ShoutoutRequest from "components/ShoutoutRequest";
import Orders from "components/Orders";
import { useEffect } from "react";
import OrderLookup from "components/OrderLookup";
import ShoutoutOrder from "components/ShoutoutOrder";
import AppLoading from "components/AppLoading";

const App = () => {
  return (
    <AppContextProvider>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </AppContextProvider>
  );
};

export const ROUTES = {
  LOGIN_DEV: "/login-dev",
  LOGIN: "/login",
  ORDERS: "/orders",
  SHOUTOUT_REQUEST: "/shoutout-request/:shoutoutId/:requestId",
  SHOUTOUT_ORDER: "/s/:shoutoutId",
  SHOUTOUT: "/:requestId",
  ORDER_LOOKUP: "/order-look-up",
};

const ScrollToTop = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
};

const RouterScrollToTop = withRouter(ScrollToTop);

const ProtectedRoute = ({ path, children }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const isLoggedIn = !!user;
  return (
    <Route
      path={path}
      component={() =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect to={{ pathname: ROUTES.ORDER_LOOKUP }} />
        )
      }
    />
  );
};

// Displaying  app loading indicator should be handled at
// the route level to prevent rendering issues with context api
const Page = ({ Component }) => {
  const { appIsLoading } = useAppContext();
  return (
    <>
      {appIsLoading && <AppLoading />}
      <Component />
    </>
  );
};

const Routes = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const isLoggedIn = !!user?.uid;
  return (
    <Router>
      <RouterScrollToTop />
      <Switch>
        {process.env.REACT_APP_ENV === "development" && (
          <Route path={ROUTES.LOGIN_DEV}>
            <LoginDev />
          </Route>
        )}
        <Route path={ROUTES.SHOUTOUT_ORDER}>
          <Page Component={ShoutoutOrder} />
        </Route>
        <Route path={ROUTES.LOGIN}>
          <Page Component={Login} />
        </Route>
        <Route path={ROUTES.ORDER_LOOKUP}>
          <Page Component={OrderLookup} />
        </Route>
        <ProtectedRoute path={ROUTES.ORDERS}>
          <Page Component={Orders} />
        </ProtectedRoute>
        <ProtectedRoute path={ROUTES.SHOUTOUT_REQUEST}>
          <Page Component={ShoutoutRequest} />
        </ProtectedRoute>
        <Route path={ROUTES.SHOUTOUT}>
          <Page Component={VideoMessage} />
        </Route>
        <Route
          exact
          path="/"
          component={() => (
            <Redirect to={isLoggedIn ? ROUTES.ORDERS : ROUTES.ORDER_LOOKUP} />
          )}
        />
      </Switch>
    </Router>
  );
};

export default App;
