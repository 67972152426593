import styled, { css } from "styled-components";
import Div from "components/Div";
import { ErrorMessage, Section, sectionPadding } from "components/common";
import { ButtonPrimary } from "notes";
import { useEffect, useState } from "react";
import { shoutoutI } from "firebase-tools";
import Spinner from "../Spinner";
import { CountryPhone } from "notes";
import { useInput } from "hooks";

const Description = styled(Div)`
  font-size: 17px;
  color: ${(props) => props.theme.colors.text};
  font-weight: 300;
  span {
    font-weight: bold;
  }
`;

const Wrapper = styled(Section)`
  padding: 0;
`;

const Content = styled(Div)`
  ${sectionPadding}
  @media all and ${(props) => props.theme.media.mobile} {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

const Divider = styled(Div)`
  height: 1px;
  background: ${(props) => props.theme.colors.gray20};
`;

const Info = styled(Div)`
  font-style: italic;
`;

const PhoneFieldWrapper = styled(Div)`
  @media all and ${(props) => props.theme.media.mobile} {
    display: block;
  }
`;

const SubmitButton = styled(ButtonPrimary)`
  margin-left: 20px;
  ${(props) =>
    props.isLoading &&
    css`
      pointer-events: none;
    `}
  @media all and ${(props) => props.theme.media.mobile} {
    width: 100%;
    margin-top: 25px;
    margin-left: 0;
  }
`;

const EditButton = styled.span`
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  color: ${(props) => props.theme.colors.linkDefault};
  font-weight: 500;
`;

const PhoneNumberText = styled.span`
  font-weight: 600;
  margin: 0 5px;
`;

const PhoneField = ({ value, onTyping, onChange, onValidate = null }) => {
  const { text, setText } = useInput(value, onChange, 1200);
  return (
    <CountryPhone
      value={text}
      onValidate={onValidate}
      onChange={(v) => {
        onTyping();
        setText(v);
      }}
    />
  );
};

const INVALID = "invalid";
const PhoneNumberInput = ({
  shoutout,
  email,
  onSubmit,
  savedPhoneNumber = null,
  responseTimeDays,
}) => {
  const { name: shoutoutName } = shoutout;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [submittedPhone, setSubmittedPhone] = useState(
    savedPhoneNumber !== null
  );
  const [isTypingNumber, setIsTypingNumber] = useState(false);

  const isPhoneNumberInvalid = formattedPhoneNumber === INVALID;
  const touched = formattedPhoneNumber !== null;

  useEffect(() => {
    if (savedPhoneNumber) setSubmittedPhone(true);
  }, [savedPhoneNumber]);

  useEffect(() => {
    (async () => {
      if (phoneNumber) {
        setIsLoading(true);
        const res = await shoutoutI
          .functions()
          .sms.verifyNumber({ phoneNumber });
        const {
          result: { phoneNumber: formattedPhoneNumber },
        } = res;
        setFormattedPhoneNumber(formattedPhoneNumber || INVALID);
        setIsTypingNumber(false);
        setIsLoading(false);
      }
    })();
  }, [phoneNumber]);

  const onSubmitPhoneNumber = () => {
    onSubmit(formattedPhoneNumber);
    setSubmittedPhone(true);
  };

  const submitButtonUI = (
    <SubmitButton
      isLoading={isLoading}
      disabled={isTypingNumber || isPhoneNumberInvalid || !touched}
      onClick={onSubmitPhoneNumber}
    >
      {isLoading ? <Spinner size="10px" /> : "Submit"}
    </SubmitButton>
  );

  const inputUI = (
    <Content>
      <Div fs_17 c_text extraBold mb_10>
        Let us know where to text you once your shout-out is ready
      </Div>
      <PhoneFieldWrapper dflex>
        <PhoneField
          value={phoneNumber}
          onTyping={() => setIsTypingNumber(true)}
          onChange={(v) => {
            if (typeof v === "string") setPhoneNumber(v);
          }}
        />
        {submitButtonUI}
      </PhoneFieldWrapper>
      {isPhoneNumberInvalid && (
        <ErrorMessage
          msg={"This does not appear to be a valid phone number."}
        />
      )}
      <Info fs_11 c_text mt_15>
        Message and data rates may apply. Consent is not a condition of
        purchase. Text back STOP to cancel.
      </Info>
    </Content>
  );

  const submittedPhoneUI = (
    <Content dflex lite fs_17>
      <Div mcontainer>
        You will receive text updates on your request at
        <PhoneNumberText>{savedPhoneNumber || phoneNumber}</PhoneNumberText>
        <EditButton
          onClick={() => {
            setSubmittedPhone(false);
          }}
        >
          (edit)
        </EditButton>
      </Div>
    </Content>
  );

  const displayedResponseTimeDays =
    responseTimeDays === 1 ? "day" : `${responseTimeDays} days`;
  return (
    <Wrapper>
      <Div mcontainer>
        <Content isHeaderSection>
          <Div extraBold fs_25>
            What Comes Next?
          </Div>
          <Description mt_5>
            {shoutoutName} will review your shout-out request and in the next{" "}
            {displayedResponseTimeDays}. When your shoutout is ready we will
            send you an email. If your video message is not created within this
            window, you will be refunded. We will send future updates to{" "}
            <span>{email}</span>.
          </Description>
        </Content>
      </Div>
      <Divider />
      {submittedPhone ? submittedPhoneUI : <Div mcontainer>{inputUI}</Div>}
    </Wrapper>
  );
};

export default PhoneNumberInput;
