var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import styled, { css } from "styled-components";
import { AppContainer } from "./layout";
import { Link } from "notes";
import { Div } from "./Div";
import { Logo } from "./Logo";
import { theme } from "./theme";
var Container = styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  background: ", ";\n  padding-top: 30px;\n  svg {\n    path {\n      fill: ", ";\n    }\n  }\n  @media all and ", " {\n    padding-bottom: 10px;\n  }\n"], ["\n  color: ", ";\n  background: ", ";\n  padding-top: 30px;\n  svg {\n    path {\n      fill: ", ";\n    }\n  }\n  @media all and ", " {\n    padding-bottom: 10px;\n  }\n"])), function (props) { return props.color; }, function (props) { return props.background; }, function (props) { return props.color; }, function (props) { return props.theme.media.mobile; });
var FooterLink = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 12px;\n  line-height: 15px;\n  color: ", ";\n  :hover,\n  :focus {\n    color: ", ";\n  }\n  margin-right: 24px;\n  @media all and ", " {\n    margin-top: 20px;\n  }\n"], ["\n  font-size: 12px;\n  line-height: 15px;\n  color: ", ";\n  :hover,\n  :focus {\n    color: ", ";\n  }\n  margin-right: 24px;\n  @media all and ", " {\n    margin-top: 20px;\n  }\n"])), function (props) { return props.color; }, function (props) { return props.color; }, function (props) { return props.theme.media.mobile; });
var Wrapper = styled(Div)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  ", "\n  padding-bottom: 35px;\n  @media all and ", " {\n    display: block;\n    padding-bottom: 4%;\n  }\n"], ["\n  width: 100%;\n  ",
    "\n  padding-bottom: 35px;\n  @media all and ", " {\n    display: block;\n    padding-bottom: 4%;\n  }\n"])), function (props) {
    return props.withLogo
        ? css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n          display: flex;\n          justify-content: space-between;\n        "], ["\n          display: flex;\n          justify-content: space-between;\n        "]))) : css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n          display: grid;\n          place-items: center;\n        "], ["\n          display: grid;\n          place-items: center;\n        "])));
}, function (props) { return props.theme.media.mobile; });
var LinksWrapper = styled(Div)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  @media all and ", " {\n    margin-top: 10px;\n  }\n"], ["\n  @media all and ", " {\n    margin-top: 10px;\n  }\n"])), function (props) { return props.theme.media.mobile; });
var links = [
    {
        label: "Privacy Policy",
        path: "/privacy",
    },
    {
        label: "Terms of Use",
        path: "/terms",
    },
    {
        label: "Contact Us",
        path: "/contact",
    },
    {
        label: "FAQ",
        path: "/faq",
    },
];
export var Footer = function (_a) {
    var _b = _a.withLogo, withLogo = _b === void 0 ? true : _b, _c = _a.color, color = _c === void 0 ? theme.colors.text : _c, _d = _a.background, background = _d === void 0 ? theme.colors.background : _d;
    var gotoPage = function (path) {
        window.open("https://" + process.env.REACT_APP_FIREBASE_AUTHDOMAIN + path);
    };
    var copyrightUI = (React.createElement(Div, { fs_12: true, c_hA6AEB2: true, mb_10: true }, "\u00A9 2021 Set.Live"));
    return (React.createElement(Container, { color: color, background: background, mcontainer: true },
        React.createElement(AppContainer, null,
            React.createElement(Wrapper, { withLogo: withLogo },
                React.createElement(Div, null,
                    withLogo && React.createElement(Logo, null),
                    copyrightUI),
                React.createElement(LinksWrapper, { flexWrap: true }, links.map(function (link, index) { return (React.createElement(FooterLink, { color: color, background: background, clickable: true, key: index, onClick: function () {
                        gotoPage(link.path);
                    } }, link.label)); }))))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
