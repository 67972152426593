import styled from "styled-components";
import MessageDetail from "./MessageDetail";
import { useDocumentData } from "react-firebase-hooks/firestore";
import Videojs from "../common/Videojs";
import { Loader } from "notes";
import Div from "../Div";
import { fstore } from "../../firebase-tools";
import Footer from "../common/Footer";
import { useParams } from "react-router";
import Header from "./Header";
import { AppLayout } from "components/common/layout";
import { useRequest } from "components/ShoutoutRequest/ShoutoutRequestContextProvider";

const Wrapper = styled(Div)`
  width: 830px;
  display: flex;
  justify-content: space-between;
  @media all and ${(props) => props.theme.media.mobile} {
    display: block;
    width: 100%;
  }
`;

const VideojsWrapper = styled(Div)`
  width: 350px;
  height: 620px;
  @media all and ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

const Container = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  @media all and ${(props) => props.theme.media.mobile} {
    display: block;
  }
`;

const VideoMessageContainer = styled(AppLayout)`
  background-color: black;
  min-height: 920px;
  @media all and ${(props) => props.theme.media.mobile} {
    min-height: 1005px;
  }
`;

const VideoMessage = () => {
  const { requestId } = useParams<{ requestId: string }>();
  const { request, isLoading } = useRequest(requestId);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <VideoMessageWithRequestData request={request} />
      )}
    </>
  );
};

const VideoMessageWithRequestData = ({ request }) => {
  const [shoutout, isLoading]: any = useDocumentData(
    fstore.doc(`shoutouts/${request.shoutoutId}`)
  );
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <VideoMessageWithData shoutout={shoutout} request={request} />
      )}
    </>
  );
};

const VideoMessageWithData = ({ request, shoutout }) => {
  const { hideSetliveHeaderLogo, headerBackgroundColor } = shoutout;
  return (
    <VideoMessageContainer>
      <Header
        withLogo={!hideSetliveHeaderLogo}
        backgroundColor={headerBackgroundColor}
      />
      <Content request={request} shoutout={shoutout} />
      <Footer color="white" background="black" />
    </VideoMessageContainer>
  );
};

const Content = ({ request, shoutout }) => {
  return (
    <Container centered c_white>
      <Wrapper spaceBetween>
        <VideojsWrapper>
          <Videojs width="100%" height="100%" src={request?.download?.source} />
        </VideojsWrapper>
        <MessageDetail shoutout={shoutout} message={request} />
      </Wrapper>
    </Container>
  );
};

export default VideoMessage;
