var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
import { Div } from "./Div";
export * from "./Div";
export * from "./theme";
export * from "./Footer";
export * from "./layout";
export * from "./overlay";
export * from "./share";
export * from "./forms";
export * from "./Test";
export * from "./Logo";
export * from "./Modal";
export * from "./AppLoading";
export * from "./WarningMessage";
export * from "./SelectMenu";
export var sectionPadding = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 36px 52px;\n  @media all and ", " {\n    padding-right: 0;\n    padding-left: 0;\n  }\n"], ["\n  padding: 36px 52px;\n  @media all and ", " {\n    padding-right: 0;\n    padding-left: 0;\n  }\n"])), function (props) { return props.theme.media.mobile; });
export var fadeinAnimation = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  animation: fadein 0.25s ease-in-out;\n  @keyframes fadein {\n    from {\n      opacity: 0;\n    }\n    to {\n      opacity: 1;\n    }\n  }\n"], ["\n  animation: fadein 0.25s ease-in-out;\n  @keyframes fadein {\n    from {\n      opacity: 0;\n    }\n    to {\n      opacity: 1;\n    }\n  }\n"])));
export var SectionHeader = styled(Div)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: none;\n  @media all and ", " {\n    font-weight: 800;\n    font-size: 20px;\n    height: 70px;\n    margin-top: 0;\n    display: flex;\n    align-items: center;\n    background: ", ";\n    color: ", ";\n    padding-left: 5%;\n    padding-right: 5%;\n  }\n"], ["\n  display: none;\n  @media all and ", " {\n    font-weight: 800;\n    font-size: 20px;\n    height: 70px;\n    margin-top: 0;\n    display: flex;\n    align-items: center;\n    background: ", ";\n    color: ", ";\n    padding-left: 5%;\n    padding-right: 5%;\n  }\n"])), function (props) { return props.theme.media.mobile; }, function (props) { return props.theme.colors.gray05; }, function (props) { return props.theme.colors.text; });
export var Section = styled(Div)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08);\n  background-color: white;\n  border-radius: 8px;\n  ", ";\n  color: ", ";\n  @media all and ", " {\n    box-shadow: none;\n    padding-right: 0;\n    padding-left: 0;\n  }\n"], ["\n  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08);\n  background-color: white;\n  border-radius: 8px;\n  ", ";\n  color: ", ";\n  @media all and ", " {\n    box-shadow: none;\n    padding-right: 0;\n    padding-left: 0;\n  }\n"])), sectionPadding, function (props) { return props.theme.colors.text; }, function (props) { return props.theme.media.mobile; });
export var MobileDivider = styled(Div)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: none;\n  @media all and ", " {\n    display: block;\n    height: ", ";\n    width: 100%;\n    background-color: #fafafa;\n    box-shadow: inset 0 2px 6px 0 rgba(0, 0, 0, 0.24);\n  }\n"], ["\n  display: none;\n  @media all and ", " {\n    display: block;\n    height: ", ";\n    width: 100%;\n    background-color: #fafafa;\n    box-shadow: inset 0 2px 6px 0 rgba(0, 0, 0, 0.24);\n  }\n"])), function (props) { return props.theme.media.mobile; }, function (props) { return (props.height ? props.height : "4px"); });
export var WhiteButton = styled(Div)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  border: solid 1px ", ";\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  user-select: none;\n  height: 40px;\n  width: 184px;\n  font-weight: bold;\n  color: ", ";\n  font-size: 17px;\n  :hover {\n    cursor: pointer;\n    opacity: 0.8;\n  }\n"], ["\n  border: solid 1px ", ";\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  user-select: none;\n  height: 40px;\n  width: 184px;\n  font-weight: bold;\n  color: ", ";\n  font-size: 17px;\n  :hover {\n    cursor: pointer;\n    opacity: 0.8;\n  }\n"])), function (props) { return props.theme.colors.text; }, function (props) { return props.theme.colors.text; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
