import { Section } from "components/common";
import Div from "components/Div";
import { usePaymentContext } from "components/PaymentContextProvider";
import styled from "styled-components";
import videoSvg from "../../../assets/video-light.svg";

const Image = styled.img`
  height: 80px;
  width: 80px;
`;

const Container = styled(Section)`
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 25px;
  background-image: url(${videoSvg});
  background-size: 140% 140%;
  background-position: -20% 20%;
  background-repeat: no-repeat;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    padding-top: 20px;
    background-position: -25% -40%;
  }
`;

const SummaryDetail = () => {
  const {
    shoutout: {
      assets: {
        icon: { path: shoutoutImageUrl },
      },
      name: shoutoutName,
    },
  } = usePaymentContext();

  return (
    <Container p_24 alignCenter>
      <Image src={shoutoutImageUrl} />
      <Div ml_25>
        <Div c_text fs_20 extraBold>
          {shoutoutName}
        </Div>
        <Div fs_15 c_border>
          Shout-Outs
        </Div>
      </Div>
    </Container>
  );
};

export default SummaryDetail;
