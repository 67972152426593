import Div from "components/Div";
import Logo from "components/common/Logo";
import { AppContainer } from "components/common/layout";
import Videojs from "components/common/Videojs";
import styled, { css } from "styled-components";
import { Icon } from "notes";
import { Backdrop as BackdropBase, ModalContainer } from "./overlay";
import ShareModal from "./ShareModal";
import { useState } from "react";
import { useMediaQuery } from "hooks";
import { theme } from "theme";

const Columns = styled(Div)`
  display: grid;
  height: 100%;
  grid-template-columns: 1.7fr 299px;
  grid-gap: 80px;
  @media all and ${(props) => props.theme.media.mobile} {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
`;

export const ContentColumns = styled(Columns)`
  margin-bottom: 80px;
  @media all and ${(props) => props.theme.media.mobile} {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    margin-bottom: 48px;
  }
`;

const WithHeaderHeight = styled(Div)`
  height: 566px;
  ${(props) =>
    props.displayVideo &&
    css`
      height: 627px;
    `}
  @media all and ${(props) => props.theme.media.mobile} {
    height: 450px;
    ${(props) =>
      props.displayVideo &&
      css`
        height: 500px;
      `}
  }
  @media all and ${(props) => props.theme.media.mobileSmall} {
    ${(props) =>
      !props.displayVideo &&
      css`
        height: 100vw;
      `}
  }
`;

const HeaderContainer = styled(WithHeaderHeight)`
  width: 100%;
  background: ${(props) => props.theme.colors.inputPlaceholderText};
  ${(props) =>
    props.backgroundImageUrl &&
    css`
      background-repeat: no-repeat;
      background-position: 50% 15%;
      background-image: url(${props.backgroundImageUrl});
      background-size: cover;
    `}
  color: ${(props) => props.theme.colors.white};
  ${(props) =>
    props.isDisabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

const HeaderGradient = styled(WithHeaderHeight)`
  position: absolute;
  background: linear-gradient(
    ${(props) => props.degrees}deg,
    #000000 0%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
  pointer-events: none;
  @media all and ${(props) => props.theme.media.mobileSmall} {
    ${(props) =>
      props.displayVideo &&
      css`
        opacity: 0.6;
      `}
  }
`;

const LogoContainer = styled(Div)`
  height: 78px;
  display: flex;
  align-items: center;
  svg {
    path {
      fill: white;
    }
  }
`;

const HeaderDivider = styled(Div)`
  background: ${(props) => props.theme.colors.orange};
  height: 4px;
  width: 288px;
  margin-top: 8px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 17px;
    width: 156px;
  }
`;

const HeaderName = styled(Div)`
  font-weight: 700;
  font-family: Work Sans;
  font-size: 32px;
  @media all and ${(props) => props.theme.media.mobile} {
    font-size: 25px;
  }
`;

const ShoutoutText = styled(Div)`
  font-weight: 300;
  font-family: Work Sans;
  font-size: 32px;
  letter-spacing: -0.46px;
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  @media all and ${(props) => props.theme.media.mobile} {
    width: 100%;
    font-size: 25px;
  }
`;

const HeaderContentWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
`;

export const HeaderText = ({ text, description = "Shout-Outs" }) => {
  return (
    <>
      <HeaderName uppercase>{text}</HeaderName>
      <HeaderDivider />
      <ShoutoutText mt_18>{description} </ShoutoutText>
    </>
  );
};

export const HeaderTextContainer = styled(Div)`
  margin-bottom: 150px;
  margin-left: 60px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-left: 0;
    margin-bottom: 70px;
  }
`;

const Backdrop = styled(BackdropBase)`
  background: black;
  opacity: 0.38;
`;

const ShareIconWrapper = styled(Div)`
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.48);
  border-radius: 4px;
  height: 46px;
  width: 46px;
  right: 6%;
  margin-top: -23px;
  z-index: 100000;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto !important;
  svg {
    path {
      fill: ${(props) => props.theme.colors.text};
    }
  }
  user-select: none;
  :hover {
    cursor: pointer;
  }
`;

const ContentWrapper = styled(Div)`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  @media all and ${(props) => props.theme.media.mobile} {
    pointer-events: none;
  }
`;

const MobileShareWrapper = styled(Div)`
  height: 0px;
  width: 100%;
`;

const MobileVideoWrapper = styled(Div)`
  display: none;
  @media all and ${(props) => props.theme.media.mobileSmall} {
    display: block;
  }
`;

const Header = ({
  backgroundImageUrl = null,
  videoUrl = null,
  children = null,
  shareUrl = null,
  withLogo = false,
  isDisabled = false,
}) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const isMobile = useMediaQuery(theme.media.mobile);

  const logoUI = (
    <AppContainer>
      <LogoContainer mcontainer>
        <Div forDesktop alignCenter>
          {withLogo && <Logo />}
        </Div>
        <Div forMobile alignCenter>
          {withLogo && <Logo height="18" width="85.5" />}
        </Div>
      </LogoContainer>
    </AppContainer>
  );

  const onCloseShareOptions = () => setIsShareModalOpen(false);
  const shareModalUI = (
    <>
      {isShareModalOpen && (
        <>
          <Backdrop onClick={onCloseShareOptions} />
          <ModalContainer>
            <ShareModal shareUrl={shareUrl} onClose={onCloseShareOptions} />
          </ModalContainer>
        </>
      )}
    </>
  );

  const videoJsProps = {
    headerSkin: true,
    src: videoUrl,
    width: "100%",
    autoPlay: true,
    autoHideControls: false,
    muted: true,
    withObjectFitCover: true,
  };
  const videoMobileUI = videoUrl && (
    <MobileVideoWrapper w100 positionAbsolute>
      <Videojs {...videoJsProps} height="500px" />
    </MobileVideoWrapper>
  );

  const mobileShareUI = shareUrl && (
    <MobileShareWrapper forMobile>
      <ShareIconWrapper
        onClick={() => {
          setIsShareModalOpen(true);
        }}
      >
        <Icon name="Send" height="38" width="38" />
      </ShareIconWrapper>
    </MobileShareWrapper>
  );

  const displayVideo = !!videoUrl;
  return (
    <>
      {!isDisabled && shareModalUI}
      <HeaderContainer
        isDisabled={isDisabled}
        displayVideo={displayVideo}
        backgroundImageUrl={backgroundImageUrl}
      >
        {videoMobileUI}
        <HeaderGradient
          degrees={isMobile ? 0 : 90}
          displayVideo={displayVideo}
        />
        <ContentWrapper positionRelative>
          {logoUI}
          <AppContainer>
            <Columns mcontainer displayVideo={displayVideo}>
              <HeaderContentWrapper>{children}</HeaderContentWrapper>
            </Columns>
          </AppContainer>
          {!isDisabled && mobileShareUI}
        </ContentWrapper>
      </HeaderContainer>
    </>
  );
};

export default Header;
