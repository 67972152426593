import { useAppContext } from "AppContextProvider";
import { fstore } from "firebase-tools";
import { getObjectByString } from "@max/common";
import { createContext, useContext, useEffect, useState } from "react";

const OrdersContext = createContext(null);

const compare = (request, searchText) => {
  const match = (field) =>
    getObjectByString(request, field)
      ?.toLowerCase()
      .includes(searchText.toLowerCase());

  return (
    match("shoutout.name") ||
    match("email") ||
    match("form.starter") ||
    match("form.requestedBy") ||
    match("form.messageFor") ||
    match("form.message") ||
    match("form.relation")
  );
};

const getRequestArray = (requests) =>
  Object.keys(requests).reduce(
    (res, key) => [...res, { id: key, ...requests[key] }],
    []
  );

export const OrdersContextProvider = ({ children }) => {
  const { user } = useAppContext();
  const [searchText, setSearchText] = useState(null);
  const [requests, setRequests] = useState({});
  const [requestsPrivateDocs, setRequestsPrivateDocs] = useState(null);
  const [requestsPublicDocs, setRequestsPublicDocs] = useState(null);

  useEffect(() => {
    if (user && user.uid) {
      const uId = user.uid;
      (async () => {
        const _requestsPrivateDocs = await fstore
          .collectionGroup("private")
          .where("requestorUid", "==", uId)
          .get();
        setRequestsPrivateDocs(_requestsPrivateDocs);
      })();

      (async () => {
        const _requestsPublicDocs = await fstore
          .collection("shoutout_requests")
          .where("requestorUid", "==", uId)
          .get();
        setRequestsPublicDocs(_requestsPublicDocs);
      })();
    }
  }, [user]);

  useEffect(() => {
    if (requestsPublicDocs)
      requestsPublicDocs.docs.forEach(async (r) => {
        setRequests((prev) => ({
          ...prev,
          [r.id]: { ...prev[r.id], ...r.data() },
        }));
      });
  }, [requestsPublicDocs]);

  useEffect(() => {
    if (requestsPrivateDocs)
      requestsPrivateDocs.docs
        .filter((d) => d.id === "data")
        .forEach(async (r) => {
          setRequests((prev) => {
            const reqId = r.ref.parent.parent.id;
            return {
              ...prev,
              [reqId]: { ...prev[reqId], ...r.data() },
            };
          });
        });
  }, [requestsPrivateDocs]);

  const requestsArray = requests ? getRequestArray(requests) : [];
  const orders = searchText
    ? requestsArray?.filter((r) => compare(r, searchText))
    : requestsArray;
  const isLoading = !requestsPublicDocs && !requestsPrivateDocs;
  return (
    <OrdersContext.Provider
      value={{
        orders: orders,
        isLoading,
        searchText,
        setSearchText,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

export const useOrdersContext = () => useContext(OrdersContext);
