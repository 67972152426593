import Div from "components/Div";
import { Form as FForm, Formik } from "formik";
import styled from "styled-components";
import {
  ErrorMessageWarning,
  Input,
  MessageForInput,
  SelectInput,
  TextareaInput,
} from "./formikInputs";
import * as yup from "yup";
import { ButtonPrimary } from "notes";
import { WhiteButton } from ".";
import { useMediaQuery } from "hooks";
import { theme } from "theme";
import FormikErrorFocus from "./FormikErrorFocus";

const HeaderText = styled(Div)`
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  @media all and ${(props) => props.theme.media.mobile} {
    font-size: 20px;
    margin-top: -3px;
  }
`;

const DescriptionText = styled(Div)`
  font-weight: 300;
  @media all and ${(props) => props.theme.media.mobile} {
    font-size: 15px;
  }
`;

const Label = styled(Div)`
  font-size: 15px;
  margin-top: 26px;
  margin-bottom: 7px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 30px;
  }
`;

const FormButtons = styled(Div)`
  @media all and ${(props) => props.theme.media.mobile} {
    flex-direction: column-reverse;
  }
`;

const UpdateButton = styled(ButtonPrimary)`
  @media all and ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

const CancelButton = styled(WhiteButton)`
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 15px;
    width: 100%;
  }
`;

const SubmitButton = styled(ButtonPrimary)`
  @media all and ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

const requestTypeOptions = {
  self: { label: "Myself", value: "self" },
  someoneElse: { label: "Someone Else", value: "someone_else" },
};

const relationOptions = [
  { text: "Friend", id: "Friend" },
  { text: "Boyfriend", id: "Boyfriend" },
  { text: "Girlfriend", id: "Girlfriend" },
  { text: "Fiancé(e)", id: "Fiancé(e)" },
  { text: "Husband", id: "Husband" },
  { text: "Wife", id: "Wife" },
  { text: "Partner", id: "Partner" },
  { text: "Dad", id: "Dad" },
  { text: "Mom", id: "Mom" },
  { text: "Parent", id: "Parent" },
  { text: "Brother", id: "Brother" },
  { text: "Sister", id: "Sister" },
  { text: "Sibling", id: "Sibling" },
  { text: "Cousin", id: "Cousin" },
  { text: "Aunt", id: "Aunt" },
  { text: "Uncle", id: "Uncle" },
  { text: "Other (describe below)", id: "Other (describe below)" },
];

const yupRequired = yup
  .string()
  .nullable()
  .required("This is a required field and cannot be blank.");
const yupSomeoneElseRequired = yup.string().nullable().when("type", {
  is: requestTypeOptions.someoneElse.value,
  then: yupRequired,
});
const validationSchema = yup.object().shape({
  type: yupRequired,
  requestedBy: yupRequired,
  starter: yupRequired,
  message: yupRequired.max(128).label("Message"),
  messageFor: yupSomeoneElseRequired,
  relation: yupSomeoneElseRequired,
});

const equalsSomeoneElse = (v) => v === requestTypeOptions.someoneElse.value;

const getMessageDetailsPlaceholder = (values) => {
  const { type, starter } = values;
  let res = "What would you like the artist to talk about? ";
  if (starter === "Message for a special occasion")
    res = `What is the special occasion? ${res}`;
  res += equalsSomeoneElse(type)
    ? "It can be helpful to include tips about how to pronounce your and the recipient's names"
    : "It can be helpful to include a tip about how to pronounce your name.";
  return res;
};

const ShoutoutForm = ({
  isEdit = false,
  onCancel = null,
  shoutout,
  request = null,
  onSubmit,
}) => {
  const {
    name: shoutoutName,
    fields: { starters },
    introduction,
  } = shoutout;
  const isMobile = useMediaQuery(theme.media.mobile);
  const inputWidth = isMobile ? "100%" : "392px";
  const headerText = `Get A Personalized Video Message From ${shoutoutName}!`;

  const headerUI = (
    <>
      <HeaderText>{headerText}</HeaderText>
      <DescriptionText mt_12>{introduction}</DescriptionText>
    </>
  );

  const _initialValues = request || {
    type: requestTypeOptions.self.value,
    requestedBy: null,
    starter: null,
    message: null,
    messageFor: null,
    relation: null,
  };

  const onSubmitForm = (values) => {
    onSubmit({
      ...values,
      messageFor:
        values.type === requestTypeOptions.self.value
          ? values.requestedBy
          : values.messageFor,
    });
  };

  const messageForDetailsUI = (
    <Div>
      <Label>Their name</Label>
      <Input
        width={inputWidth}
        name="messageFor"
        placeholder="What the artist should call them"
      />
      <Label>My Name</Label>
      <Input
        width={inputWidth}
        name="requestedBy"
        placeholder="What the recipient would call you"
      />
      <Label>Relationship</Label>
      <SelectInput
        width={inputWidth}
        name="relation"
        placeholder="How are you connected to this person?"
        options={relationOptions}
      />
    </Div>
  );

  return (
    <>
      {headerUI}
      <Formik
        initialValues={_initialValues}
        onSubmit={onSubmitForm}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, setValues, submitForm }) => (
          <FForm>
            <Div mt_25>
              <ErrorMessageWarning />
            </Div>
            <Label>Who is this message for?</Label>
            <MessageForInput
              name="type"
              options={Object.keys(requestTypeOptions).map(
                (k) => requestTypeOptions[k]
              )}
              onSelect={(v) => {
                setValues({
                  ...values,
                  type: v,
                  messageFor: null,
                  relation: null,
                });
              }}
            />
            {equalsSomeoneElse(values.type) ? (
              messageForDetailsUI
            ) : (
              <>
                <Label>My Name</Label>
                <Input
                  width={inputWidth}
                  name="requestedBy"
                  placeholder="What the artist should call you"
                />
              </>
            )}
            <Label>Conversation starter (optional)</Label>
            <SelectInput
              width={inputWidth}
              name="starter"
              placeholder="Is this message for any special occasion?"
              options={starters.map((s) => ({ id: s, text: s }))}
            />
            <Label mt_26>Message details</Label>
            <TextareaInput
              charLimit="128"
              placeholder={getMessageDetailsPlaceholder(values)}
              name="message"
            />
            <Div mt_35 mb_5>
              <Div>
                {!isEdit && (
                  <SubmitButton onClick={submitForm}>
                    Submit Request
                  </SubmitButton>
                )}
              </Div>
              {isEdit && (
                <FormButtons dflex>
                  <CancelButton mr_24 onClick={onCancel}>
                    Cancel
                  </CancelButton>
                  <UpdateButton onClick={submitForm}>
                    Update Request
                  </UpdateButton>
                </FormButtons>
              )}
            </Div>
            <FormikErrorFocus />
          </FForm>
        )}
      </Formik>
    </>
  );
};

export default ShoutoutForm;
