var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MAXTheme } from "notes";
export var colorPalette = {
    blue20: "#26a6fa",
    blue30: "#217cc2",
    blue40: "#0D2137",
    purple40: "#2c334b",
    purple50: "#252c44",
    purple55: "#20273F",
    purple60: "#07091f",
    green10: "#A0D8A1",
    green20: "#64c566",
    green30: "#349A96",
    gray04: "#EFF1F2",
    gray05: "#F5F8FB",
    gray10: "#E0E3E5",
    gray15: "#C7CCCE",
    gray20: "#9ca4a8",
    gray30: "#596266",
    gray40: "#636973",
    yellow10: "#FFF9E9",
    yellow15: "#FFE7A8",
    yellow20: "#FFE7A8",
    yellow40: "#FEB72D",
    orange40: "#FEB72D",
    background: "#F9F9F9",
    red20: "#E45C52",
    red25: "#df534b",
    red: "red",
    white: "white",
    black: "black",
};
var theme = __assign({}, MAXTheme);
theme.media = __assign(__assign({}, theme.media), { mobile: "(max-width: 1050px)" });
theme.media = __assign(__assign({}, theme.media), { mobileSmall: "(max-width: 600px)" });
theme.media = __assign(__assign({}, theme.media), { desktop: "(min-width: 1051px)" });
theme.media = __assign({}, theme.media);
theme.colors = __assign(__assign({}, theme.colors), colorPalette);
export { theme };
