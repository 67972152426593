import Div from "components/Div";
import Header, {
  HeaderTextContainer,
  HeaderText,
  ContentColumns,
} from "components/common/Header";
import { AppContainer, AppLayout } from "components/common/layout";
import styled from "styled-components";
import { Icon, ButtonPrimary } from "notes";
import Footer from "components/common/Footer";
import { useEffect } from "react";
import { theme } from "theme";
import Progress from "./Progress";
import PhoneNumberInput from "components/common/PhoneNumberInput";
import Summary from "./Summary";
import { useHistory } from "react-router";
import { useMediaQuery } from "hooks";
import { MobileDivider } from "components/common";
import {
  ShoutoutRequestContextProvider,
  useShoutoutRequestContext,
} from "./ShoutoutRequestContextProvider";
import { RequestStatus } from "@musicaudienceexchange/shoutout-interface/lib/firestore/shoutouts";
import moment from "moment";
import { useAppContext } from "AppContextProvider";

const HeaderIcon = styled(Div)`
  border-radius: 100%;
  background: ${(props) => props.theme.colors.orange40};
  height: 72px;
  width: 72px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path {
      fill: ${(props) => props.theme.colors.text};
    }
  }
`;

const ShoutoutPlaceholder = styled(Div)`
  height: 392px;
  border: solid 4px ${(props) => props.theme.colors.disabledFill};
  border-radius: 8px;
  color: ${(props) => props.theme.colors.border};
  font-size: 20px;
`;

const ViewMessage = styled(ButtonPrimary)`
  height: 56px;
  width: 100%;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 15px;
  font-weight: bold;
  font-size: 18px;
  margin-top: 32px;
  svg {
    path {
      fill: ${(props) => props.theme.colors.white};
    }
  }
`;

const MobileButton = styled(ButtonPrimary)`
  @media all and ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

const ProgressWrapper = styled(Div)`
  margin-top: -80px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
  }
`;

const ViewShoutoutWrapper = styled(Div)`
  background: ${(props) => props.theme.colors.gray05};
  padding: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray10};
`;

const PhoneNumberInputWrapper = styled(Div)`
  margin-top: 24px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

const ShoutoutRequest = () => {
  return (
    <ShoutoutRequestContextProvider>
      <ShoutoutRequestWithContext />
    </ShoutoutRequestContextProvider>
  );
};

const ShoutoutRequestWithContext = () => {
  const { setAppIsLoading } = useAppContext();
  const { isLoading } = useShoutoutRequestContext();

  useEffect(() => {
    setAppIsLoading(isLoading);
  }, [isLoading]);

  return <>{!isLoading && <ShoutoutRequestWithData />}</>;
};

const ShoutoutRequestWithData = () => {
  const {
    shoutout,
    request,
    requestId,
    requestDoc,
  } = useShoutoutRequestContext();
  const {
    name: shoutoutName,
    responseTimeDays,
    hideSetliveHeaderLogo,
    assets: {
      headerLarge: { path: backgroundImageUrl },
      headerSmall: { path: mobileBackgroundImageUrl },
    },
  } = shoutout;
  const history = useHistory();
  const isMobile = useMediaQuery(theme.media.mobile);
  const eventStatuses = request.events.map((e) => e.status);
  const isRequestRejected =
    eventStatuses.includes(RequestStatus.expired) ||
    eventStatuses.includes(RequestStatus.rejected);
  const isShoutoutReady = eventStatuses.includes(RequestStatus.completed);

  useEffect(() => {
    if (isMobile) document.body.style.backgroundColor = theme.colors.white;
    else document.body.style.backgroundColor = theme.colors.background;
  }, [isMobile]);

  const onSubmitPhoneNumber = (phoneNumber) => {
    requestDoc.private.ref.update({ phoneNumber });
  };

  const headerUI = (
    <HeaderTextContainer>
      <HeaderIcon>
        <Icon tertiary name="Video" height="40" width="40" />
      </HeaderIcon>
      <HeaderText text={shoutoutName} />
    </HeaderTextContainer>
  );

  const viewShoutout = () => {
    history.push(`/${requestId}`);
  };

  const viewShoutoutDesktopUI = (
    <>
      {isShoutoutReady ? (
        <ViewMessage onClick={viewShoutout}>
          <Div>View Message</Div>
          <Icon name="Next" />
        </ViewMessage>
      ) : (
        <ShoutoutPlaceholder mt_40 justifyCenter>
          <Div mt_120 w_288 mr_20 ml_20 textAlignCenter>
            Your shout-out will appear here when ready.
          </Div>
        </ShoutoutPlaceholder>
      )}
    </>
  );

  const viewShoutoutMobileUI = (
    <>
      {isShoutoutReady ? (
        <ViewShoutoutWrapper onClick={viewShoutout}>
          <Div>
            <Div fs_20 c_text extraBold>
              Your Shout-Out is Ready
            </Div>
            <Div c_text fs_15 lite mt_4 mb_20>
              {shoutoutName} has finished your personal video message; take a
              look and show it off to friends!
            </Div>
            <MobileButton>View Message</MobileButton>
          </Div>
        </ViewShoutoutWrapper>
      ) : (
        <ViewShoutoutWrapper justifyCenter lite c_border>
          <Div h_35 centered>
            Your shout-out will appear here when ready.
          </Div>
        </ViewShoutoutWrapper>
      )}
    </>
  );

  const daysSinceCreated = moment(new Date()).diff(
    moment(request.createdAt.seconds * 1000),
    "days"
  );
  const pendingRequestUI = !isRequestRejected && (
    <>
      <MobileDivider />
      {!isShoutoutReady && (
        <PhoneNumberInputWrapper>
          <PhoneNumberInput
            onSubmit={onSubmitPhoneNumber}
            shoutout={shoutout}
            email={request.email}
            savedPhoneNumber={request.phoneNumber}
            responseTimeDays={responseTimeDays - daysSinceCreated}
          />
        </PhoneNumberInputWrapper>
      )}
      <MobileDivider />
      <Div forMobile>{viewShoutoutMobileUI} </Div>
    </>
  );

  return (
    <AppLayout>
      <Header
        withLogo={!hideSetliveHeaderLogo}
        backgroundImageUrl={
          isMobile ? mobileBackgroundImageUrl : backgroundImageUrl
        }
      >
        {headerUI}
      </Header>
      <AppContainer>
        <ContentColumns>
          <Div>
            {!isRequestRejected && (
              <ProgressWrapper positionRelative>
                <Progress />
              </ProgressWrapper>
            )}
            {pendingRequestUI}
            <Summary
              request={request}
              isShoutoutReady={isShoutoutReady}
              isRequestRejected={isRequestRejected}
            />
          </Div>
          {!isRequestRejected && (
            <Div w100 forDesktop>
              {viewShoutoutDesktopUI}
            </Div>
          )}
        </ContentColumns>
      </AppContainer>
      <Footer
        background={isMobile ? theme.colors.white : theme.colors.background}
      />
    </AppLayout>
  );
};

export default ShoutoutRequest;
