import styled from "styled-components";
import Div from "components/Div";
import { Section as SectionBase } from "components/common";
import { usePaymentContext } from "components/PaymentContextProvider";
import { CreditCardBadge } from "notes";

const SubHeader = styled(Div)`
  color: ${(props) => props.theme.colors.subheaderDefault};
  font-weight: bold;
  margin-top: 18px;
`;

const Section = styled(SectionBase)`
  margin-top: 24px;
  @media all and ${(props) => props.theme.media.mobile} {
    padding-top: 24px;
    margin-top: 0;
  }
`;

const Title = styled(Div)`
  font-size: 25px;
  @media all and ${(props) => props.theme.media.mobile} {
    font-size: 20px;
  }
`;

const availableCreditCardBadgeTypes = [
  "amex",
  "discover",
  "mastercard",
  "visa",
];

const ContactInformation = () => {
  const {
    shoutoutDisplayPrice,
    customerInformation: { firstName, lastName, email, paymentData },
  } = usePaymentContext();
  const card = paymentData?.details?.card;
  const orderTotal = shoutoutDisplayPrice;
  return (
    <Div mcontainer>
      <Section>
        <Title extraBold fs_25>
          Customer Information
        </Title>
        <SubHeader>Contact Information</SubHeader>
        <Div lite mt_5>
          <Div>{`${firstName} ${lastName}`}</Div>
          <Div>{email}</Div>
        </Div>
        <SubHeader>Payment Method</SubHeader>
        <Div dflex mt_5>
          {availableCreditCardBadgeTypes.includes(card?.brand) && (
            <CreditCardBadge type={card?.brand} />
          )}
          <Div lite ml_10 capitalize>
            {card?.brand?.replace(/_/g, " ")}
          </Div>
          <Div lite ml_5>
            ending in {card?.last4}
          </Div>
          <Div extraBold ml_7>
            ({orderTotal})
          </Div>
        </Div>
      </Section>
    </Div>
  );
};

export default ContactInformation;
