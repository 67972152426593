import styled, { css } from "styled-components";
import Div from "components/Div";
import { AppContainer, AppLayout } from "./common/layout";
import Logo from "./common/Logo";
import Footer from "./common/Footer";
import { theme } from "theme";
import { Icon, ButtonPrimary, FormMessage } from "notes";
import * as yup from "yup";
import { Form as FForm, Formik } from "formik";
import { Input } from "./common/formikInputs";
import { useEffect, useState } from "react";
import { shoutoutI } from "firebase-tools";
import { getDomain } from "./utils";
import Spinner from "./common/Spinner";
import { ROUTES } from "App";
import { useAppContext } from "AppContextProvider";

const Wrapper = styled(Div)`
  padding: 32px 40px;
  width: 450px;
  margin-top: 10px;
  border-radius: 8px;
  border: solid 1px ${(props) => props.theme.colors.cardAddonBorder};
  @media all and ${(props) => props.theme.media.mobile} {
    border: none;
    padding: 0px;
    width: 100%;
  }
`;

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .nullable()
    .email("This does not appear to be a valid email address.")
    .required("This is a required field and cannot be blank."),
});

const SubmitButton = styled(ButtonPrimary)`
  width: 100%;
  margin-bottom: 7px;
  ${(props) =>
    props.isLoading &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}
`;

const Info = styled(FormMessage)`
  padding: 0 20px;
  margin: 24px 0;
`;

const WarningMessage = styled(Div)`
  padding: 16px;
  background: ${(props) => props.theme.colors.yellow10};
  border: solid 1px ${(props) => props.theme.colors.yellow20};
  margin-bottom: 24px;
`;

const SuccessMessage = styled.span`
  span {
    color: ${(props) => props.theme.colors.linkDefault};
    user-select: none;
    font-weight: bold;
    text-decoration: underline;
    margin-left: 2px;
    :hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;

const OrderLookupText = styled(Div)`
  font-size: 25px;
  @media all and ${(props) => props.theme.media.mobile} {
    font-size: 20px;
  }
`;

const DescriptionText = styled(Div)`
  font-size: 17px;
  @media all and ${(props) => props.theme.media.mobile} {
    font-size: 15px;
  }
`;

const OrderLookup = () => {
  const { setAppIsLoading } = useAppContext();
  const initialValues = { email: null };
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [isInvalidAccount, setIsInvalidAccount] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setAppIsLoading(false);
  }, []);

  const pageHeaderUI = (
    <Div centered h_116>
      <Logo />
    </Div>
  );

  const sendEmail = async () => {
    setIsLoading(true);
    const res = await shoutoutI.functions().auth.sendLoginEmail({
      email: submittedEmail,
      url: `${getDomain()}${ROUTES.ORDERS}`,
    });
    setIsLoading(false);
    if (!res.error) setIsLinkSent(true);
    else setIsInvalidAccount(true);
  };

  useEffect(() => {
    setIsInvalidAccount(false);
    setIsLinkSent(false);
    if (submittedEmail) sendEmail();
  }, [submittedEmail]);

  const handleSubmit = (values) => {
    setSubmittedEmail(values.email);
  };

  const warningUI = isInvalidAccount && (
    <WarningMessage>No account found with that email address.</WarningMessage>
  );

  const linkSentUI = isLinkSent && (
    <Info
      active
      content={
        <SuccessMessage>
          A link to your order history has been sent to {submittedEmail}. Didn’t
          get it? <span onClick={sendEmail}>Send Again</span>
        </SuccessMessage>
      }
      type="info"
    />
  );

  const headerUI = (
    <>
      <OrderLookupText c_text extraBold>
        Order Look Up
      </OrderLookupText>
      <DescriptionText fs_17 lite c_text mt_3 mb_20>
        Please enter the email you used at checkout to lookup your order(s).
      </DescriptionText>
    </>
  );

  const contentUI = (
    <Wrapper>
      {headerUI}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ submitForm, values }) => (
          <FForm>
            <Div mb_30>
              <Input
                width="100%"
                name="email"
                placeholder="john@email.com"
                leftIcon={<Icon form name="Email" />}
              />
            </Div>

            {values.email === submittedEmail && (
              <>
                {linkSentUI} {warningUI}
              </>
            )}
            <SubmitButton isLoading={isLoading} onClick={submitForm}>
              {isLoading ? (
                <Spinner color="white" size="10px" />
              ) : (
                "Locate Order History"
              )}
            </SubmitButton>
          </FForm>
        )}
      </Formik>
    </Wrapper>
  );

  return (
    <AppLayout>
      {pageHeaderUI}
      <AppContainer>
        <Div centered mcontainer>
          {contentUI}
        </Div>
      </AppContainer>
      <Footer withLogo={false} background={theme.colors.white} />
    </AppLayout>
  );
};

export default OrderLookup;
